
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <h1>Contact</h1>
    <p>Contact</p>
  </Layout>
)

export default IndexPage